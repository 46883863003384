<template>
  <div>
    <v-card>
      <v-app-bar dark color="primary">
        <v-icon large class="mr-3">data_usage</v-icon>
        <v-toolbar-title>User Count</v-toolbar-title>
      </v-app-bar>
      <v-card-text>
        <v-row no-gutters>
            <v-col>
            <v-menu
              ref="startDateMenu"
              v-model="startDateMenu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dataToSend.startDate"
                  label="From"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                no-title
                scrollable
                v-model="dataToSend.startDate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.startDateMenu.save(Date(startDate))"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-menu
              ref="endDateMenu"
              v-model="endDateMenu"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="To"
                  v-model="dataToSend.endDate"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                no-title
                color="primary"
                scrollable
                v-model="dataToSend.endDate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.endDateMenu.save(Date(endDate))"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row no-gutters>
        <v-col sm="6">
          <v-autocomplete
            :items="countries"
            prepend-icon="mdi-map-marker"
            item-text="title"
            item-value="title"
            v-model="dataToSend.countries"
            clearable
            autocomplete
            multiple
            label="Countries"
          ></v-autocomplete>
        </v-col>
        </v-row>

        <v-row class="justify-center align-center" no-gutters>
          <v-btn class="ma-2" @click="loadData()" color="primary">Load</v-btn>
          <v-btn class="ma-2" @click="clearFilter()" color="primary"
            >Clear Filter</v-btn
          >
          <v-btn
            class="ma-2"
            @click="exportAllUsers()"
            color="primary"
            :loading="loading"
          >
            <v-icon class="mr-2">cloud_download</v-icon>Export
          </v-btn>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-card color="#076827" dark height="200px">
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title class="font-weight-thin ml-3">
                  Total User Count
                  <h2 class="font-weight-light ml-3">
                    <number
                      :from="0"
                      :to="totalUsers"
                      :format="(number) => number.toFixed(0)"
                      :duration="2"
                      :delay="1"
                      easing="Power3.easeOut"
                    />
                  </h2>
                </v-card-title>
                <v-avatar size="100" tile>
                  <v-icon x-large>group</v-icon>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
           <v-col cols="12" md="6" sm="12">
            <v-card color="#3C308E" dark height="200px">
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title class="font-weight-thin ml-3">
                  Last User Joined Date
                  <h2
                    v-if="lastDate.length > 0 && lastDate != 'No User Found'"
                    class="font-weight-light ml-3"
                  >
                    {{ $moment(lastDate).format("DD-MM-YYYY") }}
                  </h2>
                  <h2
                    v-if="lastDate === 'No User Found'"
                    class="font-weight-light ml-3"
                  >
                    {{ lastDate }}
                  </h2>
                </v-card-title>
                <v-avatar size="100" tile>
                  <v-icon x-large>calendar_today</v-icon>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
const service = new RestResource();
import Resource from "@/services/dataServiceAdmin.js";
const adminService = new Resource();

export default {
  data() {
    return {
      totalUsers: 0,
      lastDate: [],
      countries: [],
      startDate: null,
      endDate: null,
      startDateMenu: false,
      endDateMenu: false,
      loading: false,
      snack: false,
      dataToSend: {
        startDate: undefined,
        endDate: undefined,
      },
    };
  },

  mounted() {
    this.getCountry();
    },


  methods: {

    async getCountry() {
      let r = await adminService.getConfig("countries");

      this.countries = r.data;
    },

    async getTotalUsers(params) {
      this.$setLoader();
      let res = await service.getTotalUserCount(params);
      this.totalUsers = res.data.count;
      this.$disableLoader();
    },

    async getLastUserJoinedDate(params) {
      let res = await service.getLastUserJoinedDate(params);
      this.lastDate =
        undefined == res.data.date ? "No User Found" : res.data.date.created_at;
    },

    clearFilter() {
      this.dataToSend.startDate = undefined;
      this.dataToSend.endDate = undefined;
      this.dataToSend.countries = undefined;
    },

    async loadData() {
      let v = await this.$validator.validateAll();
      if (v) {
        this.getTotalUsers(this.dataToSend);
        this.getLastUserJoinedDate(this.dataToSend);
      }
    },

    async exportAllUsers() {
      let v = await this.$validator.validateAll();
      if (v) {
        (this.dataToSend.email = this.$store.state.userProfile.email),
          (this.dataToSend.protected = false);
        this.loading = true;
        service
          .exportUsers(this.dataToSend)
          .then(() => {
            this.loading = false;
            this.$store.state.showDialogMessage = true;
            this.$store.state.dialogMessageTitle = "Export Started";
            this.$store.state.dialogMessageText = `Your file will be delivered on ${this.$store.state.userProfile.email}`;
          })
          .catch((error) => {
            /* eslint-disable-next-line */
            console.log(error);
            this.loading = false;
          });
      }
    },
  },
};
</script>